<template>
  <CRow class="pb-0 mb-0">
    <CCol col="12" lg="12">
      <CForm novalidate :disabled="submitting">
        <CCard id="user-settings-card" class="px-0 m-0">
          <CCardHeader v-if="title">
            <CRow class="justify-content-around">
              <CCol col="10">
                <span class="float-left"
                  ><h5>{{ title }}</h5></span
                >
              </CCol>
              <CCol col="2">
                <span v-if="canEdit" v-show="!edit" class="float-right">
                  <CButton
                    color="info"
                    size="sm"
                    variant="outline"
                    @click="edit = !edit"
                  >
                    <CIcon name="cil-pencil" />
                    Edit
                  </CButton></span
                >
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="px-3">
            <CRow>
              <CCol>
                <CInput
                  type="text"
                  label="First Name"
                  :readonly="!edit"
                  :value.sync="$v.form.first.$model"
                  :isValid="checkIfValid('first')"
                  invalidFeedback="*Required"
                >
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                  type="text"
                  label="Last Name"
                  :readonly="!edit"
                  :value.sync="$v.form.last.$model"
                  :isValid="checkIfValid('last')"
                  invalidFeedback="*Required"
                >
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CSelect
                  label="User Role"
                  :readonly="!edit"
                  :diabled="!edit"
                  :value.sync="$v.form.role.$model"
                  :options="options"
                  placeholder=""
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                class="pb-0"
                  type="email"
                  label="Email"
                  vertical
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                  invalidFeedback="Must be a Valid Email address"
                >
                  <template #prepend-content
                    ><CIcon name="cil-envelope-closed"
                  /></template>
   
                </CInput>
              </CCol>
            </CRow>
            <CRow v-show="false">
              <CCol>
                <CInputCheckbox class="m-0 ml-4 pt-0"
                label="User Must Verify Email First"
                :value="form.verify_email"
                >
                   
                </CInputCheckbox>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                  type="password"
                  label="Password"
                  placeholder="Password"
                  vertical
                  :value.sync="$v.form.password.$model"
                  :isValid="checkIfValid('password')"
                  :invalidFeedback="passwordMessage"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
              </CCol>
            </CRow>
            <!--CRow v-if="!$v.form.password.$invalid"-->
            <CRow>
              <CCol>
                <CInput
                  :isValid="checkIfValid('confirmPassword')"
                  :value.sync="$v.form.confirmPassword.$model"
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirm Password"
                  autocomplete="new-password"
                  invalidFeedback="Passwords must match"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
              </CCol>
            </CRow>
            
            <CFormGroup
              wrapperClasses="input-group pt-2"
              description="ex. (999) 999-9999"
            >
              <template #prepend-content>
                <CIcon name="cil-phone" />
              </template>
              <template #label> Phone Number (Optional) </template>
              <template #input>
                <masked-input
                  type="tel"
                  name="phone"
                  class="form-control"
                  v-model="form.phone_number"
                  :mask="[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]"
                  :guide="true"
                  :readonly="!edit"
                  placeholderChar="#"
                />
              </template>
              
            </CFormGroup>
            
          </CCardBody>
          <CCardFooter>
            <CRow class="justify-content-center">
              <CCol>
                <CButtonGroup v-show="edit" class="float-right">
                  <CButton
                    color="secondary"
                    size="sm"
                    variant=""
                    class="rounded mx-1"
                    @click="handleCancelClick"
                  >
                    <CIcon name="cil-x-circle" />
                    Cancel
                  </CButton>
                  <span v-show="edit && !success" class="float-right">
                    <CButton
                      color="info"
                      size="sm"
                      :disabled="!isValid"
                      variant=""
                      @click="handleSaveClick"
                    >
                      <CIcon name="cil-user-follow" />
                      <CSpinner v-show="submitting" color="success" size="sm" />
                      Add User
                    </CButton>
                  </span>
                </CButtonGroup>
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
              <CCol>
                <h4>{{ message }}</h4>
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
              <CCol class="reason-text">
                {{ reason }}
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
        <CElementCover
          v-show="submitting"
          :boundaries="[{ sides: ['top', 'left'], query: '#media-body' }]"
          :opacity="0.1"
        >
          <h1 class="d-inline">Adding User...</h1>
          <CSpinner size="6xl" color="info" />
        </CElementCover>
      </CForm>
    </CCol>
  </CRow>
</template>

<style scoped>
.label {
  overflow-wrap: normal;
}
.reason-text {
  font-size: 12px;
  
  color: var(--danger);
}
.table-overflow-elipses {
  table-layout: fixed;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
</style>

<script>
import MaskedInput from "vue-text-mask";
import { validationMixin } from "vuelidate";
import UserRoleMixin from "@/mixins/UserRoleMixin";
import {
  required,
  minLength,
  email,
  sameAs,
  helpers,
  alphaNum,
} from "vuelidate/lib/validators";

const defaultIMG =
  "https://poc.aarcomm.io/app/grafana/avatar/fd14f83a37594373470f1c9fdb18ad08";

export default {
  name: "UserAddForm",
  props: {
    title: String,
    passwordMessage: {
      type: String,
      default: "Password must be 8 Characters Long",
    },
    verifyEmail:{
      type: String,
      default: true, 
    }
  },
  data() {
    return {
      usersOpened: null,
      form: this.getEmptyForm(),
      _selectedUser: undefined,
      edit: true,
      success: false,
      passwordReset: false,
      message: "",
      reason: "",
      submitting: false,
    };
  },
  components: {
    MaskedInput,
  },
  mixins: [validationMixin, UserRoleMixin],
  computed: {
    isAdmin() {
      return this.$auth.isAdmin;
    },
    canEdit() {
      // If the user is admin OR the user is the user, they can edit
      if (this.$auth.isAdmin) {
        return true;
      } else if (this.$auth.user_id === this.user.uid) return true;
      else return false;
    },
    user() {
      return this.form;
    },
    options() {
      if (this.roles) {
        //let ret = [];

        let temp = this.roles.map((item, index) => {
          return {
            key: index,
            id: index,
            value: item.name,
            label: item.alias,
            disabled: !this.edit,
          };
        });

        // TODO: Sort by operator First

        return temp;
      } else {
        return [
          {
            key: "1",
            label: "Operator",
            value: "viewer",
            disabled: !this.edit,
          },
          { key: "2", label: "Admin", value: "admin", disabled: !this.edit },
        ];
      }
    },
    userInfo() {
      // Returns a map of user summary info
      return [
        { key: "uid", label: "User ID:", value: this.user.uid },
        { key: "role", label: "User Role:", value: this.role },

        {
          key: "registered",
          label: "Registered: ",
          value: this.getDateTime(this.user.registered),
        },
      ];
    },
    role() {
      // Note: Roles are Custom For Each User
      let item = this.form;
      // TODO: Create a Library function to extract the role "Nickname"
      if (!item || !item.role) return "viewer";
      let role = item.role.toLowerCase(); // Force to lower case

      return role;
    },

    userId() {
      return this.$route.params.id;
    },
    defaultImg() {
      return defaultIMG;
    },
    userImage() {
      if (this.user) return this.user.img;
      /*
      else if (this.user.picture) return this.user.picture;
      */ else return defaultIMG;
    },
    userEmail() {
      return this.userData.filter((param) => param.key === "email")[0].value;
    },
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },

    isValid() {
      return !this.$v.form.$invalid; //!this.$v.form.assetId.$invalid;
    },
  },

  validations: {
    // Custom Validator Functions for Form Input
    form: {
      email: {
        required,
        minLength: minLength(3),
        email,
      },
      verify_email:{

      },

      role: {
        //required,
      },

      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      first: {
        required,
      },
      last: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    handleCancelClick(e) {
      this.edit = true;

      this.initForm(this._selectedUser);
      this.$v.$reset();
      this.$emit("cancel", this);
    },

    onSuccess(res) {
      this.message = "Successfully Added User";
      this.reason = "";
      this.success = true;
      this.$emit("success", this.form); // Expose the Data
    },
    onFail(res) {
      this.message = "Failed to Create the User";
      if (res && res.data) {
        this.reason = res.data.message;
      }
      this.success = false;
      this.$emit("fail", this.message); // Expose the Data
    },
    onConflict(res){
      this.message = "Failed to Create the User";
      if (res && res.data) {
        this.reason = res.data.message || "User already exists";
      }
      this.success = false;
      this.$emit("fail", this.message); // Expose the Data
    },
    async handleSaveClick(e) {
      //this.edit = !this.edit;
      // TODO: Save user Settings here
      // TODO: Submit form then return on Success

      let connection = this.$auth.connection;
      console.log(this.form);

      let user = { ...this.form }; // Copy from data
      (user.organization = this.$auth.user.organizationId),
        (user.connection = connection); // TODO: get from Setttings!
      user.role = this.role;
      console.log(user);

      // TODO: This is just a form. Actual Submit should be handled by parent

      try {
        this.submitting = true;
        let res = await this.$app.addUser(user);  // Call the Applicaiton specific method for this 
        //let res = await this.$api.createUser(user);
        console.log(res);
        if (res.status == 201) {
          this.onSuccess(res);
        }
        else if(res.status===409){
          this.onConflict(res); 
  
        } else {
          this.onFail(res); 
        }
      } catch (err) {
        // Failed to Add User
        console.log(err, err.response);
        this.onFail(err.response); 

      } finally {
        this.submitting = false;
      }
    },

    getDateTime(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },

    getEmptyForm() {
      return {
        first: "",
        last: "",
        email: "",
        verify_email: true,
        password: "",
        confirmPassword: "",
        role: "",
        phone_number: "",
      };
    },

    initForm(data) {
      this.form = this.getEmptyForm();
      this.message = "";
      this.reason = "";
    },
    load() {
      this.success = false;
      this.initForm(this._selectedUser); // Data backing object
      this.$v.$reset();
    },
  },
  activated() {
    this.load();
  },
  mounted() {
    this.load();
  },
};
</script>
