var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "pb-0 mb-0" },
    [
      _c(
        "CCol",
        { attrs: { col: "12", lg: "12" } },
        [
          _c(
            "CForm",
            { attrs: { novalidate: "", disabled: _vm.submitting } },
            [
              _c(
                "CCard",
                {
                  staticClass: "px-0 m-0",
                  attrs: { id: "user-settings-card" }
                },
                [
                  _vm.title
                    ? _c(
                        "CCardHeader",
                        [
                          _c(
                            "CRow",
                            { staticClass: "justify-content-around" },
                            [
                              _c("CCol", { attrs: { col: "10" } }, [
                                _c("span", { staticClass: "float-left" }, [
                                  _c("h5", [_vm._v(_vm._s(_vm.title))])
                                ])
                              ]),
                              _c("CCol", { attrs: { col: "2" } }, [
                                _vm.canEdit
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.edit,
                                            expression: "!edit"
                                          }
                                        ],
                                        staticClass: "float-right"
                                      },
                                      [
                                        _c(
                                          "CButton",
                                          {
                                            attrs: {
                                              color: "info",
                                              size: "sm",
                                              variant: "outline"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.edit = !_vm.edit
                                              }
                                            }
                                          },
                                          [
                                            _c("CIcon", {
                                              attrs: { name: "cil-pencil" }
                                            }),
                                            _vm._v(" Edit ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "CCardBody",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInput", {
                                attrs: {
                                  type: "text",
                                  label: "First Name",
                                  readonly: !_vm.edit,
                                  value: _vm.$v.form.first.$model,
                                  isValid: _vm.checkIfValid("first"),
                                  invalidFeedback: "*Required"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.first,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInput", {
                                attrs: {
                                  type: "text",
                                  label: "Last Name",
                                  readonly: !_vm.edit,
                                  value: _vm.$v.form.last.$model,
                                  isValid: _vm.checkIfValid("last"),
                                  invalidFeedback: "*Required"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.last,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "User Role",
                                  readonly: !_vm.edit,
                                  diabled: !_vm.edit,
                                  value: _vm.$v.form.role.$model,
                                  options: _vm.options,
                                  placeholder: ""
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.role,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-user" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInput", {
                                staticClass: "pb-0",
                                attrs: {
                                  type: "email",
                                  label: "Email",
                                  vertical: "",
                                  value: _vm.$v.form.email.$model,
                                  isValid: _vm.checkIfValid("email"),
                                  invalidFeedback:
                                    "Must be a Valid Email address"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.email,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-envelope-closed" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ]
                        },
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInputCheckbox", {
                                staticClass: "m-0 ml-4 pt-0",
                                attrs: {
                                  label: "User Must Verify Email First",
                                  value: _vm.form.verify_email
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInput", {
                                attrs: {
                                  type: "password",
                                  label: "Password",
                                  placeholder: "Password",
                                  vertical: "",
                                  value: _vm.$v.form.password.$model,
                                  isValid: _vm.checkIfValid("password"),
                                  invalidFeedback: _vm.passwordMessage
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.password,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-lock-locked" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CInput", {
                                attrs: {
                                  isValid: _vm.checkIfValid("confirmPassword"),
                                  value: _vm.$v.form.confirmPassword.$model,
                                  label: "Confirm Password",
                                  type: "password",
                                  placeholder: "Confirm Password",
                                  autocomplete: "new-password",
                                  invalidFeedback: "Passwords must match"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.form.confirmPassword,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-lock-locked" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("CFormGroup", {
                        attrs: {
                          wrapperClasses: "input-group pt-2",
                          description: "ex. (999) 999-9999"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-content",
                            fn: function() {
                              return [
                                _c("CIcon", { attrs: { name: "cil-phone" } })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "label",
                            fn: function() {
                              return [_vm._v(" Phone Number (Optional) ")]
                            },
                            proxy: true
                          },
                          {
                            key: "input",
                            fn: function() {
                              return [
                                _c("masked-input", {
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "tel",
                                    name: "phone",
                                    mask: [
                                      "(",
                                      /[1-9]/,
                                      /\d/,
                                      /\d/,
                                      ")",
                                      " ",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/
                                    ],
                                    guide: true,
                                    readonly: !_vm.edit,
                                    placeholderChar: "#"
                                  },
                                  model: {
                                    value: _vm.form.phone_number,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "phone_number", $$v)
                                    },
                                    expression: "form.phone_number"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "CCardFooter",
                    [
                      _c(
                        "CRow",
                        { staticClass: "justify-content-center" },
                        [
                          _c(
                            "CCol",
                            [
                              _c(
                                "CButtonGroup",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.edit,
                                      expression: "edit"
                                    }
                                  ],
                                  staticClass: "float-right"
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      staticClass: "rounded mx-1",
                                      attrs: {
                                        color: "secondary",
                                        size: "sm",
                                        variant: ""
                                      },
                                      on: { click: _vm.handleCancelClick }
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-x-circle" }
                                      }),
                                      _vm._v(" Cancel ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.edit && !_vm.success,
                                          expression: "edit && !success"
                                        }
                                      ],
                                      staticClass: "float-right"
                                    },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          attrs: {
                                            color: "info",
                                            size: "sm",
                                            disabled: !_vm.isValid,
                                            variant: ""
                                          },
                                          on: { click: _vm.handleSaveClick }
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-user-follow" }
                                          }),
                                          _c("CSpinner", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.submitting,
                                                expression: "submitting"
                                              }
                                            ],
                                            attrs: {
                                              color: "success",
                                              size: "sm"
                                            }
                                          }),
                                          _vm._v(" Add User ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        { staticClass: "justify-content-center" },
                        [_c("CCol", [_c("h4", [_vm._v(_vm._s(_vm.message))])])],
                        1
                      ),
                      _c(
                        "CRow",
                        { staticClass: "justify-content-center" },
                        [
                          _c("CCol", { staticClass: "reason-text" }, [
                            _vm._v(" " + _vm._s(_vm.reason) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CElementCover",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.submitting,
                      expression: "submitting"
                    }
                  ],
                  attrs: {
                    boundaries: [
                      { sides: ["top", "left"], query: "#media-body" }
                    ],
                    opacity: 0.1
                  }
                },
                [
                  _c("h1", { staticClass: "d-inline" }, [
                    _vm._v("Adding User...")
                  ]),
                  _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }