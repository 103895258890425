
/**
 * An Extensible Mixin to help validate Roles within a Component
 * 
 * Note: This is meant to be extensible therefore we implement as a mixin
 * A Users App could use a different plugin as needed. 
 * 
 */


import Vue from "vue"; 


const DEFAULT_ROLE = {name:"viewer", alias: "View Only", description: "View Only"}; 

const UserRoleMixin = Vue.extend({
    name: "UserRoleMixin",
    props:{
        /** Pass in the roles as a Prop or as data ??? */
        
        roles: {
            type: Array,
        },
        
    }, 
    data() {
        return {
            //roles: [], 
            defaultRole: DEFAULT_ROLE
        }
    }, 
    methods:{


        /** User Passes in a Role String and we return user role Object
         * 
         */
        getUserRole(roleName){
            if(!roleName || !this.roles){
                return this.defaultRole; 
            }
            
            // TODO: Is it easier to use a Hashmap since role names are unique 
            let myRole = this.roles.find(item=>item.name===roleName);
            return myRole;
        }, 
        /** Get the Role Alias for the given Role Name  */
        getRoleAlias(roleName){

            let temp = this.getUserRole(roleName);
            if(temp){
                return temp.alias; 
            }
            else{
                return this.defaultRole.alias; 
            }
            
        },
        /** Returns User Role for a given Alias. 
         * Note: Aliases must also be unique 
         */
        getRoleByAlias(roleAlias){

            if(!roleAlias || !this.roles){
                return this.defaultRole; 
            }
            
            // TODO: Is it easier to use a Hashmap since role names are unique 
            let myRole = this.roles.find(item=>item.alias===roleAlias);
            return myRole;
            
        }
    }
}); 



export default UserRoleMixin; 